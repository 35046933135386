<template>
	<div class="mb-4">
		<CRow class="mb-0">
			<CCol lg="2">
				<Datepicker v-model="date_from" input-class="form-control" placeholder="Due Date from" />
			</CCol>
			<CCol lg="2">
				<Datepicker v-model="date_to" input-class="form-control" placeholder="Due Date To" />
			</CCol>
			<CCol lg="2">
				<CInput v-model="personnel" placeholder="Personnel"> </CInput>
			</CCol>
			<CCol lg="2">
				<v-select label="setting_name" :options="taskList.data" :reduce="(item) => item.id"
					v-model="renewal_task_id" placeholder="Renewal Type">
				</v-select>
			</CCol>
			
			<CCol lg="2" class="float-right">
				
			</CCol>
			<CCol lg="2" class="float-right">
				<CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
			</CCol>
		</CRow>
		<CRow class="mt-2">
			<CCol lg="10"> </CCol>
			<CCol lg="2" class="float-right">
				<CButton size="sm" block color="info" @click="clearSearch"><i class="icon-magnifier"></i> Clear Search
				</CButton>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import config from "../../config.js";
import axios from "../../axios";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import "vue-select/dist/vue-select.css";
export default {
	name: "BookingTypeSearch",
	data() {
		return {
			personnel: "",
			renewal_task_id: "",
			date_from: "",
			date_to: "",
			taskList: {
				data: [],
			},
			option_status:[
				'All Status','Due soon','Overdue'
			]
		};
	},
	props: {
		status : {
			default : 'All Status'
		}
	},
	mounted() {
		this.getRenewalTask();
	},
	components: { vSelect, Datepicker },
	methods: {
		search() {
			const data = {
				personnel: this.personnel,
				renewal_task_id: this.renewal_task_id,
				date_from: this.date_from,
				date_to: this.date_to,
				status : this.status
			};
			this.$emit("depot-search-query", data);
		},
		clearSearch() {
			this.personnel = "";
			this.renewal_task_id = "";
			this.date_from = "";
			this.date_to = "";
			const data = {
				personnel: this.personnel,
				renewal_task_id: this.renewal_task_id,
				date_from: this.date_from,
				date_to: this.date_to,
			};
			this.$emit("depot-search-query", data);
		},
		getRenewalTask() {
			axios.get(config.api_path + "/reference/settings-list", {
				params: {
					setting_type: "license_renewal_task",
				},
			})
			.then((response) => {
				this.taskList = response.data;
			});
		},
	},
};
</script>
<style scoped></style>
